import { Style } from '@capacitor/status-bar'
import { Button, cn } from '@lemonenergy/lemonpie-components'
import { foundations } from '@lemonenergy/lemonpie-theme'
import { Link } from '@remix-run/react'
import { useEffect } from 'react'

import type { ThemeType } from './Stories'

import NonUserYetButton from '~/components/buttons/NonUserYetButton'
import setStatusBarScheme from '~/utils/statusbar.util'

const MarketingStory = ({
  marketingTextStart,
  marketingTextEnd,
  backgroundImage,
  theme = 'dark',
  className,
}: {
  marketingTextStart: string
  marketingTextEnd: string
  backgroundImage: JSX.Element
  theme?: ThemeType
} & React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
  useEffect(() => {
    return () => {
      setStatusBarScheme({
        style: Style.Dark,
        backgroundColor: foundations.colors.background.main,
      })
    }
  }, [])

  return (
    <div
      className={cn(
        'flex flex-1 flex-col justify-end px-large py-larger',
        className,
      )}
    >
      {backgroundImage}
      <div className="z-[1]">
        <p
          className={cn(
            'mx-0 my-large text-[44px] font-medium leading-[42px] -tracking-[2px]',
            theme === 'dark' ? 'fg-inverted-main' : 'fg-main',
          )}
        >
          {marketingTextStart}{' '}
          <span
            className={
              theme === 'dark'
                ? 'fg-expressive-inverted'
                : 'fg-expressive-strong'
            }
          >
            {marketingTextEnd}
          </span>
        </p>
        <div className="flex flex-col items-center gap-larger">
          <Button
            asChild
            variant="primary"
            inverted={theme === 'dark'}
            onClick={(e) => e.stopPropagation()}
          >
            <Link to="/login">Entrar no aplicativo</Link>
          </Button>
          <NonUserYetButton inverse={theme === 'dark'} />
        </div>
      </div>
    </div>
  )
}

export default MarketingStory
