import { cn } from '@lemonenergy/lemonpie-components'

import type { ThemeType } from './Stories'

const StoryProgress = ({
  progress,
  theme,
}: {
  progress: number
  theme: ThemeType
}) => {
  return (
    <div
      className={cn(
        'size-full flex-grow rounded-smallest',
        theme === 'dark' ? 'bg-[#ffffff40]' : 'bg-soft-hover',
      )}
    >
      <div
        style={{ width: `${progress}%` }}
        className={cn(
          'h-full',
          theme === 'dark' ? 'bg-main' : 'bg-expressive-strong',
        )}
      />
    </div>
  )
}

function getProgress({
  selectedStory,
  position,
  progress,
}: {
  selectedStory: number
  position: number
  progress: number
}): number {
  if (position < selectedStory) {
    return 100
  }

  if (position === selectedStory) {
    return progress
  }

  return 0
}

const StoriesProgressBar = ({
  storiesLength,
  selectedStory,
  progress,
  theme,
}: {
  storiesLength: number
  selectedStory: number
  progress: number
  theme: ThemeType
}): JSX.Element => {
  const sequenceArray = Array.from(Array(storiesLength), (_, k) => k)

  return (
    <div className="flex h-[14px] w-full flex-row justify-center gap-smallest px-0 py-6">
      {sequenceArray.map((position) => (
        <StoryProgress
          key={`story-progress-${position}`}
          progress={getProgress({
            selectedStory,
            position,
            progress,
          })}
          theme={theme}
        />
      ))}
    </div>
  )
}

export default StoriesProgressBar
