export const LemonAccountImage = ({
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="360"
    height="439"
    viewBox="0 0 360 439"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M287.214 219.203L287.214 219.205C287.268 323.852 358.053 410.453 453.471 435.856L455.357 436.358L455.357 434.406L455.357 4.00217L455.357 2.05057L453.471 2.55265C358.053 27.9552 287.268 114.556 287.214 219.203Z"
      stroke="#51B88E"
      strokeWidth="3"
    />
    <path
      d="M119.306 219.203L119.306 219.205C119.36 323.852 190.145 410.453 285.562 435.856L287.448 436.358L287.448 434.406L287.448 4.00217L287.448 2.05057L285.562 2.55265C190.145 27.9552 119.36 114.556 119.306 219.203Z"
      stroke="#51B88E"
      strokeWidth="3"
    />
    <path
      d="M-48.5977 219.206L-48.5977 219.205C-48.5432 114.558 22.2418 27.9565 117.659 2.55391L119.545 2.05184L119.545 4.00343L119.545 434.408L119.545 436.359L117.659 435.857C22.2418 410.454 -48.5432 323.853 -48.5977 219.206Z"
      stroke="#51B88E"
      strokeWidth="3"
    />
  </svg>
)

export const ConsumptionImage = ({
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="360"
    height="466"
    viewBox="0 0 360 466"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M180.32 0V465.554" stroke="#8AFFB6" strokeWidth="3" />
    <path
      d="M409.641 232.778L-48.9995 232.778"
      stroke="#8AFFB6"
      strokeWidth="3"
    />
    <path
      d="M392.176 321.879L-31.5356 143.678"
      stroke="#8AFFB6"
      strokeWidth="3"
    />
    <path
      d="M343.207 396.624L17.4314 68.9244"
      stroke="#8AFFB6"
      strokeWidth="3"
    />
    <path
      d="M268.74 447.553L91.8966 17.9986"
      stroke="#8AFFB6"
      strokeWidth="3"
    />
    <path
      d="M268.174 17.7583L92.4667 447.793"
      stroke="#8AFFB6"
      strokeWidth="3"
    />
    <path
      d="M342.916 68.6299L17.7244 396.927"
      stroke="#8AFFB6"
      strokeWidth="3"
    />
    <path
      d="M392.332 144.065L-31.6938 321.494"
      stroke="#8AFFB6"
      strokeWidth="3"
    />
  </svg>
)

export const EnvironmentImage = ({
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="360"
    height="484"
    viewBox="0 0 360 484"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M180.001 481.997C147.66 481.997 116.217 475.633 86.5429 463.084C57.948 450.989 32.2943 433.701 10.2967 411.703C-11.7038 389.703 -28.9891 364.052 -41.0836 335.457C-53.6333 305.783 -59.9971 274.34 -59.9971 241.999C-59.9971 209.657 -53.6333 178.212 -41.0836 148.54C-28.9891 119.948 -11.7038 94.2943 10.2967 72.2938C32.2943 50.2962 57.948 33.0079 86.5429 20.9135C116.214 8.36382 147.66 2 180.001 2C212.343 2 243.788 8.36382 273.46 20.9135C302.055 33.0079 327.706 50.2932 349.706 72.2909C371.704 94.2885 388.992 119.942 401.087 148.537C413.636 178.212 420 209.654 420 241.996C420 274.337 413.636 305.78 401.087 335.454C388.992 364.049 371.704 389.703 349.706 411.7C327.709 433.698 302.055 450.986 273.46 463.078C243.788 475.627 212.343 481.991 180.001 481.991V481.997Z"
      stroke="#4F5250"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M180.001 438.229C153.548 438.229 127.842 433.03 103.592 422.773C80.214 412.885 59.2376 398.748 41.2433 380.757C23.2489 362.762 9.11199 341.786 -0.77367 318.408C-11.0299 294.161 -16.2295 268.452 -16.2295 241.998C-16.2295 215.545 -11.0299 189.839 -0.77367 165.589C9.1149 142.211 23.2489 121.235 41.2433 103.243C59.2376 85.2489 80.214 71.112 103.592 61.2263C127.839 50.9701 153.548 45.7705 180.001 45.7705C206.455 45.7705 232.161 50.9701 256.411 61.2263C279.789 71.1149 300.765 85.2519 318.757 103.243C336.751 121.238 350.888 142.214 360.774 165.592C371.03 189.842 376.229 215.548 376.229 242.001C376.229 268.455 371.03 294.161 360.774 318.411C350.885 341.789 336.748 362.765 318.757 380.759C300.765 398.751 279.789 412.888 256.411 422.776C232.161 433.033 206.455 438.232 180.001 438.232V438.229Z"
      stroke="#4F5250"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M180.001 394.461C159.439 394.461 139.467 390.423 120.644 382.463C102.483 374.78 86.1838 363.795 72.1957 349.806C58.2076 335.818 47.2219 319.519 39.5392 301.359C31.5764 282.535 27.541 262.563 27.541 242.001C27.541 221.439 31.5793 201.466 39.5392 182.643C47.219 164.482 58.2076 148.183 72.1957 134.195C86.1838 120.207 102.483 109.221 120.644 101.539C139.47 93.5759 159.439 89.5405 180.001 89.5405C200.563 89.5405 220.536 93.5788 239.359 101.539C257.52 109.221 273.819 120.207 287.807 134.195C301.795 148.183 312.781 164.482 320.463 182.643C328.426 201.466 332.462 221.439 332.462 242.001C332.462 262.563 328.423 282.535 320.463 301.359C312.784 319.519 301.795 335.818 287.807 349.806C273.819 363.795 257.52 374.78 239.359 382.463C220.533 390.426 200.563 394.461 180.001 394.461Z"
      stroke="#4F5250"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
  </svg>
)
